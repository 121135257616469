<template>
  <div>
    <div
      class="h-screen flex w-full bg-img vx-row no-gutter justify-center pt-5"
      style="overflow: hidden"
    >
      <div class="vx-col sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 sm:m-0 m-4">
        <ApplicationsContainer
          title="In-Progress Applications"
          color="warning"
          icon="icon-loader"
          :students="in_progress_students"
        />

        <ApplicationsContainer
          title="Completed Applications"
          color="success"
          icon="icon-check-square"
          :students="completed_students"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ApplicationsContainer from './components/ApplicationsContainer'
import common_data from './common_data'
export default {
  data () {
    return {
      in_progress_students: [],
      completed_students: []
    }
  },
  components: {
    ApplicationsContainer
  },
  created () {
    const stu_list = common_data.students_list
    this.in_progress_students = stu_list.filter(
      student => !student.application_completed
    )
    this.completed_students = stu_list.filter(
      student => student.application_completed
    )
  }
}
</script>

<style lang="scss" scoped>
.greet-user {
  position: relative;

  .decore-left {
    position: absolute;
    left: 0;
    top: 0;
  }

  .decore-right {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>

<template>
  <div>
    <div class="vx-row">
      <div class="vx-col sm:w-2/5 w-full mt-5">
        <vs-list-item
          class="item-content"
          :title="student_name"
          :subtitle="`${student_division} - ${student_grade}`"
        >
          <template slot="avatar">
            <vx-tooltip text="Complete Your Application">
              <vs-button
                v-if="application_status === null"
                icon-pack="feather"
                icon="icon-check-circle"
                size="small"
                color="primary"
                type="border"
                radius
              ></vs-button>
            </vx-tooltip>
            <vx-tooltip text="Clone This Application">
              <vs-button
                v-if="application_status !== null"
                class="mt-0"
                radius
                color="primary"
                type="border"
                icon-pack="feather"
                icon="icon-copy"
              ></vs-button>
            </vx-tooltip>

            <vs-avatar class="item-header" size="large" :text="student_name"></vs-avatar>
          </template>
        </vs-list-item>
      </div>
      <div class="vx-col sm:w-2/5 w-full mt-5">
        <vs-slider
          v-if="application_status !== null"
          disabled
          v-model="sliderStep"
          color="primary"
          :step="1"
          :max="4"
        />
      </div>
      <div class="vx-col sm:w-1/5 w-full mt-5">
        <div class="vx-row w-full">
          <div v-if="application_status !== null">
            <vs-chip class="mt-1" :color="chipColor">{{sliderText}}</vs-chip>
          </div>
        </div>
        <div v-if="application_status === null">
          <RadialChart :percentage="parseInt((application_last_step + 1) / 7 * 100)" />
        </div>
      </div>
    </div>
    <vs-divider />
  </div>
</template>


<script>
import RadialChart from './RadialChart'
import VxTooltip from '@/layouts/components/vx-tooltip/VxTooltip.vue'
export default {
  name: 'AppliactionItem',
  components: { RadialChart, 'vx-tooltip': VxTooltip },
  data () {
    return {
      sliderStep: 0,
      sliderText: ''
    }
  },
  props: {
    student_name: {
      type: String,
      required: true
    },
    student_division: {
      type: String,
      required: true
    },
    student_grade: {
      type: String,
      required: true
    },
    application_status: {
      type: String
    },
    application_last_step: {
      type: Number
    }
  },
  methods: {
    getSliderStep () {
      switch (this.application_status) {
      case 'Recieved':
        return 0
      case 'ShortListed':
        return 1
      case 'On Assesment':
        return 2
      case 'Waiting List':
      case 'Disqualified':
        return 3
      case 'Qualified':
        return 4
      }
    }
  },
  computed: {
    chipColor () {
      switch (this.sliderText) {
      case 'Recieved':
        return 'primary'
      case 'ShortListed':
        return ''
      case 'On Assesment':
        return 'indigo'
      case 'Waiting List':
        return 'warning'
      case 'Qualified':
        return 'success'
      case 'Disqualified':
        return 'danger'
      }
    }
  },
  created () {
    this.sliderText = this.application_status
    this.sliderStep = this.getSliderStep()
  }
}
</script>

<style scoped>
.item-header {
  font-size: 1.5em;
}
.item-content {
  font-size: 1.75em;
}
</style>
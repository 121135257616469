<template>
  <vue-apex-charts
    type="radialBar"
    height="90"
    width="75"
    stroke="10"
    :options="radialBarChart.chartOptions"
    :series="radialBarChart.series"
  ></vue-apex-charts>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: 'RadialChart',
  components: {
    VueApexCharts
  },
  data () {
    return {
      radialBarChart: {
        series: [],
        chartOptions: {
          colors: ['#7367F0'],
          stroke: {
            width: -10
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: '10px',
                  offsetY: 2
                },
                value: {
                  show: false
                }
              }
            }
          },
          labels: []
        }
      }
    }
  },
  props: {
    percentage: {
      type: Number,
      required: true
    }
  },
  created () {
    this.radialBarChart.chartOptions.labels = [`${this.percentage}%`]
    this.radialBarChart.series = [this.percentage]
  }
}
</script>
<template>
  <vs-card style="max-height: 25%; overflow-y: scroll">
    <vs-list>
      <vs-list-header
        class="list-header"
        icon-pack="feather"
        :icon="icon"
        :title="title"
        :color="color"
      ></vs-list-header>
      <ApplicationItem
        v-for="student in students"
        :key="student.application_id"
        :student_name="student.name"
        :student_division="student.division"
        :student_grade="student.grade"
        :application_status="student.application_status"
        :application_last_step="student.last_step"
      />
    </vs-list>
  </vs-card>
</template>


<script>
import ApplicationItem from './ApplicationItem'
export default {
  components: { ApplicationItem },
  props: {
    title: {
      type: String,
      required: true
    },
    color: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    students: {
      type: Array,
      required: true
    }
  },
  data () {
    return {}
  }
}
</script>

<style scoped>
.list-header {
  font-size: 2em;
}
</style>